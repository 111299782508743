.carousel {
  width: 100% !important; /* Asegura que el carrusel ocupe el ancho completo */
}

.carousel .carousel-slider {
  width: 100% !important;
}

.carousel .thumb {
  margin: 0 !important; /* Ajustar margen entre las miniaturas */
  padding: 0; /* Eliminar padding si es necesario */
  display: inline-block; /* Asegura que las miniaturas se muestren en línea */
}

.carousel .thumbs-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  overflow: hidden; /* Asegura que no haya desbordamiento */
  margin: 0px;
}

.carousel .thumbs {
  display: flex;
  justify-content: center;
}

/* Media query para aplicar padding-left solo en dispositivos móviles */
@media (max-width: 768px) {
  .carousel .thumbs-wrapper {
    padding-left: 10px;
  }
}
