.cuadro{
  cursor: pointer;
  position:relative;
  height: 150px;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  flex-direction: row;
  width: 100%;
  border: solid 1px #bbb;
  cursor: pointer;
  opacity: 0.3;
  transition: background-color 1s;
  transition: opacity 1s;
  border-radius: 5px;
  text-decoration: none;
}

.cuadro:hover{
  opacity: 1;
  background-color: #eee;
}

.cuadro:link,
.cuadro:hover,
.cuadro:focus{
  color: inherit;
}
.content-input{

}
.input{
  opacity: 0;
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
