.content-menu{
    background: #052b4f;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.content-menu  a{
    color: white;
}
.content-menu .item-orange a{
    text-align: center;
    display: flex; /* Usamos flexbox para centrar vertical y horizontalmente */
    justify-content: center; /* Centramos horizontalmente */
    align-items: center; /* Centramos verticalmente */
    text-align: center; /* Alineamos el texto en el centro */
    width: 100%; /* Opcional: para ocupar todo el ancho disponible */
    height: 100%; /* Opcional: para ocupar toda la altura disponible */
}
.item-orange{
    margin-top: 14px;
    background: #fb8c00;
    padding: 10px;
    text-align: center;
}