.resumen-image-background{
    width: 130px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('./index.png');
    background-position: center;
    background-repeat: no-repeat;
}
.resumen-image-background img{
    width: 100%;
}
.resumen-image-label{
    font-size: 12px;
}