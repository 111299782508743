.transparent-dialog .MuiPaper-root{
    background: rgba(255, 255, 255, 0); /* Cambia el valor alfa (0.5) según la transparencia deseada */
    box-shadow: none !important;
}

.transparent-dialog{
    background: rgba(255, 255, 255, 0.5); /* Cambia el valor alfa (0.5) según la transparencia deseada */ 
}


@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}