.dashboard-content {
  background-image: url("../../assets/png/background-home.png");
  background-size: cover; /* Mantén el aspecto cover */
  background-position: center bottom; /* Centro horizontal y desde abajo hacia arriba */
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  background-repeat: repeat;
  flex: 1;
}

.dashboard-center {
  padding: 50px 0;
  width: 100%; /* Cambia el ancho a 100% */
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  background: "#333";
}

.content-usuario-center {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  width: 100%;
  height: 100%; /* Utiliza el 100% de la altura de la ventana */
}

/* Estilos generales para dispositivos móviles */
@media (max-width: 768px) {
  .content-usuario-center {
    margin-top: 10px;
    height: auto; /* Utiliza el 100% de la altura de la ventana */
  }
}
