html,
body,
#root,
.may {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.pac-container{
  z-index: 100000;
}

.cuadro-naranja{
  position: absolute;
  right:-80px;
  top: 0;
  width: 120px;
  height: 99%;
  background: #f6903e;
  margin: 0.5%;
  z-index: 20;
}

.title-main{
  margin-bottom: 40px !important;
}

div.select2023 .label{
  font-size: 16px;
  color:rgba(0, 0, 0, 0.6);
}

select.select2023{
  padding: 17px;
  font-size: 16px;
  width: 100%;
  border-radius: 40px;  
  background-color: transparent;
  border: solid 1px #ccc;
}

.Select-new{
  padding: 0 !important; 
  position: relative;   
}

.Select-new .label{
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  line-height:1.4375em;
  padding: 0;
  display: block;
  overflow: hidden;
  max-width: 100%; 
  transform-origin: top left !important; 
  position: absolute;
  top:  0;
  left: 0;
}

.Select-new select{
  padding: 14px !important;
  border: none !important;
}

.Select-new .MuiInputBase-colorPrimary{
  margin-top: 24px;
  border-radius: 30px;
  border: solid #ccc 1px;
}

.Select-new .css-2shlp-MuiInputBase-root-MuiInput-root::before {
  border: none !important;
}
.Select-new .css-2shlp-MuiInputBase-root-MuiInput-root::after {
  border: none !important;
}



.bg-primary{
  width: 40px;
  height: 40px;
  background-color: rgba(251, 140, 0, 0.8); /* Color con opacidad (0.7) */
  color: white;
  font-size: larger;
}

.text-secondary{
  font-family: 'Bruno Ace', cursive !important;
}


.image-button img {
  transition: filter 0.3s, cursor 0.3s, transform 0.3s;
}

.image-button img:hover {
  filter: grayscale(100%); /* Desatura la imagen al 100% cuando se pasa el cursor sobre ella */
  cursor: pointer; /* Cambia el cursor a una mano para indicar que es interactivo */
  transform: scale(1.1);
}

.legend {
  opacity: 1 !important;
}

.carousel-status{
  display: none;
}

.MuiTabs-flexContainer{
  justify-content: center;
}

a,
a:link,
a:hover,
a:visited{
  text-decoration: none;
}

.borderRadius input{
  padding: 15px !important;
}

.borderRadius fieldset{
  border-radius: 50px !important;
}

.white g,
.white g g path,
.white g g g path,
.white g g g g path,
.white g path{
  fill: #fff;
}

.white{
  color: white;
}

.img-content{
  overflow: hidden;
  width: 100%;
  border: solid 1px #ccc;
}

.img-content.active{
  border: solid 3px #ccc;
}

.img-fluid{
  width: 100%;
  transition: transform .2s; /* Animation */
}

.img-fluid.active{
  transform: scale(1.2);
}

.capitalized-text {
  text-transform: capitalize;
}

.bgMain{
  background-color: #f0f3f6;
  min-height: 100vh;
}

.paper{
  min-height: 70vh;
}
.cursor-pointer{
  cursor: pointer;
}

.uppercase{
  text-transform: uppercase;
}

.title-h3{
  font-weight: bold;
}

/*
.title-h2{
  font-size:24px !important;
}
.title-h4{
  font-size:18px !important;
}
.title-h6{
  font-size:12px !important;
}
*/

.position-relative{
  position: relative;
}

.position-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  background-color: rgba(251, 140, 0, 0.8); /* Color con opacidad (0.7) */
  color: white;
  font-size: larger;
}


.border-bottom{
  border-bottom: 1px solid #ab47bc;
}

.photo-content{
  height: 10vh;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.centrar{
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.add-item{
  border: dashed 1px #333;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.noStyle::after,
.noStyle::before{
  border: none !important;
}

.noStyle svg,
.noStyle input{ 
  display: none;
}




@media only screen and (max-width: 767px) {
  /* Estilos para dispositivos móviles */
  .borderRadius input{
    padding: 10px !important;
  }
}

.avatar-img-driver {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
}