.header {
  min-height: 80vh;
  background-color: #f3f3f3;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

.header.bg1{
  background-image: url("../../assets/png/mountain1.png");
}

.header.bg2{
  background-image: url("../../assets/png/mountain1.png");
}

.header .header-image {
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.header .header-title {
  font-size: 50px;
}

.custom-appbar {
  background: transparent !important; /* Establece el fondo como transparente */
}

.swiper-button-prev, .swiper-button-next {
  color: gray !important;
}

.swiper-pagination-bullet-active {
	background: #FB8C00 !important;
}

/* Media Query para dispositivos con un ancho máximo de 768px, como teléfonos móviles */
@media (max-width: 768px) {
  .header {
    min-height: 50vh; /* Ajusta la altura para dispositivos móviles */
  }

  .header .header-image {
    width: 80%;
  }

  .header-image {
    width: 80%;
    margin-top: 20px;
  }

  .header .header-title {
    font-size: 24px; /* Ajusta el tamaño de fuente para dispositivos móviles */
  }
}
