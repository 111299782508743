.section-1 {
  background-color: #f3f3f3;
  background-image: url("../../assets/png/background-home.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

.section-1 .section-1-image {
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.section-1 .section-1-title {
  font-size: 50px;
}

.custom-appbar {
  background: transparent !important; /* Establece el fondo como transparente */
}

.section-2 {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.section-dashboard {
  background-color: #f3f3f3;
  background-image: url("../../assets/png/background-home.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

.section-dashboard .section-dashboard-image {
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

/* Media Query para dispositivos con un ancho máximo de 768px, como teléfonos móviles */
@media (max-width: 768px) {
  .section-1 {
    min-height: 50vh; /* Ajusta la altura para dispositivos móviles */
  }

  .section-1 .section-1-image {
    width: 80%;
  }

  .section-1 .section-1-title {
    font-size: 24px; /* Ajusta el tamaño de fuente para dispositivos móviles */
  }
}
