.home-content{
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  width: 100%;
  min-height: 100vh; /* Utiliza el 100% de la altura de la ventana */
  background: url("../../assets/svgs/background_home2.svg") center/cover no-repeat; /*https://loading.io/background/m-wave/ donde se generó*/
}
.home-center{
  padding: 50px 0;
  width: 60%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  background: "#333";
}
.home-title{
  text-align: center;
  font-size: 80px !important;
  text-transform: uppercase;
}
.home-subtitle{
  text-align: center;
  font-size: 20px !important;
  text-transform: uppercase;
}

/* Estilos generales para todos los dispositivos */

/* Estilos específicos para dispositivos móviles */
@media only screen and (max-width: 767px) {
  /* Aquí puedes escribir los estilos que deseas aplicar para dispositivos móviles */
  .home-title,
  .home-subtitle{
    display: none;
  }
}
